import React from "react";
import Layout from "../components/Layout";
import NavTwo from "../components/NavTwo";
import Footer from "../components/Footer";
import Blog from "../components/Blog";
import { Router } from "@reach/router"
import Subscribe from "../components/Subscribe";
import { APP_NAME } from "../config/constants";

const BlogPage = () => (
  <Layout pageTitle={`Blog | ${APP_NAME}`}>
    <NavTwo />
    <div className="thm-gray-bg111">
      <Router>
        <Blog exact path="/blog" />
        <Blog path="/blog/:id" />
      </Router>
      <Subscribe />
      <Footer />
    </div>
  </Layout>
)

export default BlogPage;
